import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicLink } from '@prismicio/react'
import { isFilled } from '@prismicio/helpers'

export const Hero = ({ slice }) => {
  return (
    <section className="WellbeingHero Hero">
      <div className="Container Hero__flex">
        <div
          className={`Hero_image_container ${
            slice.primary.image_side ? 'image_right' : 'image_left'
          }`}
        >
          {isFilled.image(slice.primary.background_image) && (
            <GatsbyImage
              className={
                slice.primary.image_side
                  ? 'Hero_background_image_right'
                  : 'Hero_background_image_left'
              }
              image={slice.primary.background_image.gatsbyImageData}
              alt={slice.primary.background_image.alt || ''}
            />
          )}
          {isFilled.image(slice.primary.image) && (
            <GatsbyImage
              className="Hero_main_image"
              image={slice.primary.image.gatsbyImageData}
              alt={slice.primary.image.alt || ''}
            />
          )}
        </div>
        <div className="header_and_text">
          {isFilled.richText(slice.primary.header.richText) && (
            <PrismicRichText field={slice.primary.header.richText} />
          )}
          {isFilled.richText(slice.primary.text.richText) && (
            <PrismicRichText field={slice.primary.text.richText} />
          )}
          {slice.primary.button_label && slice.primary.button_url?.url && (
            <div className="hero_button">
              <PrismicLink
                href={slice.primary.button_url.url}
                className="light-blue-btn"
              >
                {slice.primary.button_label}
              </PrismicLink>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment UpdatedPageDataBodyHero on PrismicUpdatedPageDataBodyHero {
    id
    primary {
      image_side
      header {
        richText
      }
      text {
        richText
      }
      image {
        gatsbyImageData
        url
        alt
      }
      background_image {
        gatsbyImageData
        url
        alt
      }
      button_label
      button_url {
        url
      }
    }
  }
`
